<template>
  <vs-table
      ref="table"
      v-model="selected"
      search
      stripe
      :multiple="selectMultiple"
      :sst="true"
      :data="clients"
      @search="search"
  >
    <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-end"
    >
      <p v-show="searchQuery && !view" class="mr-4 mb-4">
        Search results for '{{ searchQuery }}'
      </p>

      <!-- View/Duplicates -->
      <vs-dropdown
          vs-trigger-click
          class="cursor-pointer mb-4 mr-4 items-per-page-handler"
      >
        <div
            class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
        >
          <span class="mr-2"> {{ viewLabel }} </span>
          <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
        </div>
        <vs-dropdown-menu>
          <vs-dropdown-item @click="$emit('view', null)">
            <span>All</span>
          </vs-dropdown-item>
          <vs-dropdown-item @click="$emit('view', 'inactive')">
            <span>Inactive</span>
          </vs-dropdown-item>
          <vs-dropdown-item @click="$emit('view', 'dup_name')">
            <span>Duplicates - Name</span>
          </vs-dropdown-item>
          <vs-dropdown-item @click="$emit('view', 'dup_email1')">
            <span>Duplicates - Email 1</span>
          </vs-dropdown-item>
          <vs-dropdown-item @click="$emit('view', 'dup_email2')">
            <span>Duplicates - Email 2</span>
          </vs-dropdown-item>
          <vs-dropdown-item @click="$emit('view', 'dup_phone1')">
            <span>Duplicates - Phone 1</span>
          </vs-dropdown-item>
          <vs-dropdown-item @click="$emit('view', 'dup_phone2')">
            <span>Duplicates - Phone 2</span>
          </vs-dropdown-item>
        </vs-dropdown-menu>
      </vs-dropdown>
      <!-- Actions -->
      <vs-dropdown
          vs-trigger-click
          class="cursor-pointer mb-4 mr-4 items-per-page-handler"
      >
        <div
            class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
        >
          <span class="mr-2"> Action </span>
          <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
        </div>
        <vs-dropdown-menu>
          <vs-dropdown-item @click="toggleSelect">
                        <span>{{
                            selectMultiple ? 'Single Select' : 'Multiple Select'
                          }}</span>
          </vs-dropdown-item>
          <vs-dropdown-item divider></vs-dropdown-item>
          <vs-dropdown-item @click="$emit('uploadClients')">
            <span>Upload</span>
          </vs-dropdown-item>
          <vs-dropdown-item
              v-if="selectMultiple"
              @click="$emit('deleteSelected')"
          >
            <span>Delete</span>
          </vs-dropdown-item>
        </vs-dropdown-menu>
      </vs-dropdown>
      <!-- ITEMS PER PAGE -->
      <vs-dropdown
          vs-trigger-click
          class="cursor-pointer mb-4 mr-4 items-per-page-handler"
      >
        <div
            class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
        >
          <span class="mr-2"> {{ itemsPerPage }} </span>
          <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
        </div>
        <vs-dropdown-menu>
          <vs-dropdown-item @click="$emit('itemsPerPage', 5)">
            <span>5</span>
          </vs-dropdown-item>
          <vs-dropdown-item @click="$emit('itemsPerPage', 10)">
            <span>10</span>
          </vs-dropdown-item>
          <vs-dropdown-item @click="$emit('itemsPerPage', 20)">
            <span>20</span>
          </vs-dropdown-item>
          <vs-dropdown-item @click="$emit('itemsPerPage', 50)">
            <span>50</span>
          </vs-dropdown-item>
        </vs-dropdown-menu>
      </vs-dropdown>
    </div>

    <template slot="thead">
      <vs-th>Name</vs-th>
      <vs-th>Broker</vs-th>
      <vs-th>Date Added</vs-th>
      <vs-th>Phone</vs-th>
      <vs-th>Email</vs-th>
      <vs-th v-if="!selectMultiple">Detail 1</vs-th>
      <vs-th v-if="!selectMultiple">Detail 2</vs-th>
      <vs-th v-if="!selectMultiple">Actions</vs-th>
    </template>

    <template slot-scope="{ data }">
      <tbody>
      <vs-tr
          :data="client"
          :key="index"
          v-for="(client, index) in data"
      >
        <vs-td>
                        <span
                            v-if="client.user"
                            class="link"
                            v-on:click="
                                $router.push({
                                    name: 'user',
                                    params: { id: client.user.id },
                                })
                            "
                        >{{ client.name }}</span
                        >
          <span v-else>{{ client.name }}</span>
        </vs-td>

        <vs-td>
                        <span
                            v-if="client.broker"
                            class="link"
                            v-on:click="
                                $router.push({
                                    name: 'broker',
                                    params: { pk: client.broker.email },
                                })
                            "
                        >{{ client.broker.company_name }}</span
                        ><br/>
          <span v-if="client.batch" class="text-xs">{{
              client.batch.description
            }}</span>
        </vs-td>

        <vs-td>
          <vx-tooltip
              :text="formatDateAndTime(client.date_added)"
          >
            {{ timeDiffCat(client.date_added)[0] }}
          </vx-tooltip>
        </vs-td>

        <vs-td>
          {{ client.phone1 }} <br/>
          {{ client.phone2 }}
        </vs-td>

        <vs-td>
          {{ client.email1 }} <br/>
          {{ client.email2 }}
        </vs-td>

        <vs-td v-if="!selectMultiple">
          <vs-list>
            <vs-list-item
                icon-pack="feather"
                icon="icon-code"
                :title="
                                                client.coupon ||
                                                'No created coupon'
                                            "
            ></vs-list-item>
            <vs-list-item
                v-if="client.code_sent"
                icon-pack="feather"
                icon="icon-send"
                :title="
                                                'Sent on ' +
                                                formatDate(
                                                    client.code_sent_date
                                                )
                                            "
            ></vs-list-item>
            <vs-list-item
                v-if="client.code_clicked"
                icon-pack="feather"
                icon="icon-external-link"
                :title="
                                                'Clicked on ' +
                                                formatDate(
                                                    client.code_clicked_date
                                                )
                                            "
            ></vs-list-item>
            <vs-list-item
                v-if="client.code_used"
                icon-pack="feather"
                icon="icon-check"
                :title="
                                                'Account created on ' +
                                                formatDate(
                                                    client.code_used_date
                                                )
                                            "
            ></vs-list-item>
            <vs-list-item
                v-if="client.code_completed"
                icon-pack="feather"
                icon="icon-check"
                :title="
                                                'Will completed on ' +
                                                formatDate(
                                                    client.code_completed_date
                                                )
                                            "
            ></vs-list-item>
          </vs-list>
        </vs-td>

        <vs-td v-if="!selectMultiple">
          <vs-list>
            <vs-list-item
                v-if="client.phone1"
                icon-pack="feather"
                icon="icon-phone"
                :title="client.phone1"
            ></vs-list-item>
            <vs-list-item
                v-if="client.phone2"
                icon-pack="feather"
                icon="icon-phone"
                :title="client.phone2"
            ></vs-list-item>
            <vs-list-item
                v-if="client.email1"
                icon-pack="feather"
                icon="icon-mail"
                :title="client.email1"
            ></vs-list-item>
            <vs-list-item
                v-if="client.email2"
                icon-pack="feather"
                icon="icon-mail"
                :title="client.email2"
            ></vs-list-item>
          </vs-list>
        </vs-td>

        <vs-td v-if="!selectMultiple">
          <vs-button
              type="flat"
              size="small"
              icon-pack="feather"
              icon="icon-edit-2"
              class="mr-2"
              v-on:click="
                                            $emit('selectedEdit', client)
                                        "
          ></vs-button>
          <vs-button
              type="flat"
              size="small"
              icon-pack="feather"
              icon="icon-trash"
              color="danger"
              class="mr-2"
              v-on:click="deleteSingle(client)"
          ></vs-button>
        </vs-td>

<!--        <template-->
<!--            class="expand-user"-->
<!--            slot="expand"-->
<!--            v-if="!selectMultiple"-->
<!--        >-->
<!--          <div class="con-expand-users w-full">-->
<!--            <div-->
<!--                class="con-btns-user flex items-center justify-between"-->
<!--            >-->
<!--              <div-->
<!--                  class="con-userx flex items-center justify-start"-->
<!--              ></div>-->
<!--              <div class="flex">-->

<!--              </div>-->
<!--            </div>-->
<!--            <div class="vs-row">-->
<!--              <div class="vs-col w-1/2">-->
<!--                <vs-list>-->
<!--                  <vs-list-item-->
<!--                      icon-pack="feather"-->
<!--                      icon="icon-code"-->
<!--                      :title="-->
<!--                                                client.coupon ||-->
<!--                                                'No created coupon'-->
<!--                                            "-->
<!--                  ></vs-list-item>-->
<!--                  <vs-list-item-->
<!--                      v-if="client.code_sent"-->
<!--                      icon-pack="feather"-->
<!--                      icon="icon-send"-->
<!--                      :title="-->
<!--                                                'Sent on ' +-->
<!--                                                formatDate(-->
<!--                                                    client.code_sent_date-->
<!--                                                )-->
<!--                                            "-->
<!--                  ></vs-list-item>-->
<!--                  <vs-list-item-->
<!--                      v-if="client.code_clicked"-->
<!--                      icon-pack="feather"-->
<!--                      icon="icon-external-link"-->
<!--                      :title="-->
<!--                                                'Clicked on ' +-->
<!--                                                formatDate(-->
<!--                                                    client.code_clicked_date-->
<!--                                                )-->
<!--                                            "-->
<!--                  ></vs-list-item>-->
<!--                  <vs-list-item-->
<!--                      v-if="client.code_used"-->
<!--                      icon-pack="feather"-->
<!--                      icon="icon-check"-->
<!--                      :title="-->
<!--                                                'Account created on ' +-->
<!--                                                formatDate(-->
<!--                                                    client.code_used_date-->
<!--                                                )-->
<!--                                            "-->
<!--                  ></vs-list-item>-->
<!--                  <vs-list-item-->
<!--                      v-if="client.code_completed"-->
<!--                      icon-pack="feather"-->
<!--                      icon="icon-check"-->
<!--                      :title="-->
<!--                                                'Will completed on ' +-->
<!--                                                formatDate(-->
<!--                                                    client.code_completed_date-->
<!--                                                )-->
<!--                                            "-->
<!--                  ></vs-list-item>-->
<!--                </vs-list>-->
<!--              </div>-->
<!--              <div class="vs-col w-1/2">-->
<!--                <vs-list>-->
<!--                  <vs-list-item-->
<!--                      v-if="client.phone1"-->
<!--                      icon-pack="feather"-->
<!--                      icon="icon-phone"-->
<!--                      :title="client.phone1"-->
<!--                  ></vs-list-item>-->
<!--                  <vs-list-item-->
<!--                      v-if="client.phone2"-->
<!--                      icon-pack="feather"-->
<!--                      icon="icon-phone"-->
<!--                      :title="client.phone2"-->
<!--                  ></vs-list-item>-->
<!--                  <vs-list-item-->
<!--                      v-if="client.email1"-->
<!--                      icon-pack="feather"-->
<!--                      icon="icon-mail"-->
<!--                      :title="client.email1"-->
<!--                  ></vs-list-item>-->
<!--                  <vs-list-item-->
<!--                      v-if="client.email2"-->
<!--                      icon-pack="feather"-->
<!--                      icon="icon-mail"-->
<!--                      :title="client.email2"-->
<!--                  ></vs-list-item>-->
<!--                </vs-list>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </template>-->

      </vs-tr>
      </tbody>
    </template>
  </vs-table>
</template>

<script>
import {textFormat} from '@/mixins/TextFormat'
import {tableHelpers} from '@/mixins/tableHelpers'

export default {
  name: 'ClientsAllTable',
  mixins: [textFormat, tableHelpers],
  props: ['data', 'itemsPerPage', 'dataSelected', 'view'],
  data() {
    return {
      searchQuery: '',
      status: 'All',
      selectMultiple: false,
    }
  },
  computed: {
    selected: {
      set(value) {
        this.$emit('selected', value)
      },
      get() {
        return this.dataSelected
      },
    },
    clients() {
      if (this.data) return this.data.data
      return null
    },
    viewLabel() {
      if (this.view === 'dup_name') return 'Duplicates - Name'
      if (this.view === 'dup_email1') return 'Duplicates - Email 1'
      if (this.view === 'dup_email2') return 'Duplicates - Email 2'
      if (this.view === 'dup_phone1') return 'Duplicates - Phone 1'
      if (this.view === 'dup_phone2') return 'Duplicates - Phone 2'
      return 'All'
    },
  },
  methods: {
    search(value) {
      if ((value && value.length > 3) || value === '') {
        this.searchQuery = value
        this.$emit('search', value)
      }
    },
    setLimit(status, value) {
      this.status = status
      this.$emit('limit', value)
    },
    toggleSelect() {
      this.selected = []
      this.selectMultiple = !this.selectMultiple
    },
    deleteSingle(data) {
      this.$emit('selected', data)
      this.$emit('singleDelete')
    },
  },
}
</script>
