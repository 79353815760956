<template>
    <SideBarModal title="Client Edit" :show="show" @close="$emit('close')">
        <template v-slot:content>
            <div class="vx-row mb-2">
                <div class="vx-col w-full">
                    <vs-input
                        v-model="clientData.name"
                        label="Name"
                        class="w-full"
                    />
                </div>
            </div>

            <div class="vx-row mb-2">
                <div class="vx-col w-full">
                    <vs-input
                        v-model="clientData.phone1"
                        label="Phone 1"
                        class="w-full"
                    />
                </div>
            </div>

            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-input
                        v-model="clientData.phone2"
                        label="Phone 2"
                        class="w-full"
                    />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-input
                        v-model="clientData.email1"
                        label="Email 1"
                        class="w-full"
                    />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-input
                        v-model="clientData.email2"
                        label="Email 2"
                        class="w-full"
                    />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <p class="vs-input--label">
                        Disables auto invitations and reminders
                    </p>
                    <vs-checkbox
                        v-model="clientData.active"
                        label="Email 2"
                        class="w-full"
                        >Active</vs-checkbox
                    >
                </div>
            </div>
        </template>
        <template v-slot:buttons>
            <vs-button
                type="flat"
                color="success"
                class="mr-3"
                @click="clientSave"
                >Save</vs-button
            >

            <vs-button type="flat" color="danger" @click="$emit('close')"
                >Cancel</vs-button
            >
        </template>
    </SideBarModal>
</template>

<script>
import { http } from '@/services'

import SideBarModal from '@/components/common/SideBarModal'

export default {
    name: 'EditClientModal',
    components: {
        SideBarModal,
    },
    props: ['data', 'show'],
    watch: {
        data: {
            deep: true,
            immediate: true,
            handler(data) {
                this.clientData = { ...data }
            },
        },
    },
    data() {
        return {
            clientData: null,
        }
    },
    computed: {},
    methods: {
        clientSave() {
            if (this.clientData && this.clientData.id) {
                delete this.clientData.broker
                delete this.clientData.coupon
                delete this.clientData.batch
                http.patch('client_detail', this.clientData)
                    .then((response) => {
                        this.$emit('newData')
                        this.$emit('close')
                        this.$vs.notify({
                            text: 'Saved',
                            color: 'success',
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        },
    },
}
</script>

<style lang="scss">
.dropdown-button-container {
    display: flex;
    align-items: center;

    .btnx {
        border-radius: 5px 0px 0px 5px;
    }

    .btn-drop {
        border-radius: 0px 5px 5px 0px;
        border-left: 1px solid rgba(255, 255, 255, 0.2);
    }
}
.con-vs-dropdown--menu {
    z-index: 70000;
}
</style>
