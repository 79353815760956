<template>
    <div id="data-list-list-view" class="data-list-container" v-if="clients">
        <ClientsAllTable
            :data="clients"
            :itemsPerPage="itemsPerPage"
            :dataSelected="selected"
            :view="view"
            @itemsPerPage="setItemsPerPage"
            @search="search"
            @view="setView"
            @limit="setLimit"
            @selected="selected = $event"
            @deleteSelected="selected.length ? (show.deleteModal = true) : null"
            @selectedEdit="selectedEdit = $event"
            @singleDelete="show.deleteModal = true"
            @uploadClients="show.uploadModal = true"
        ></ClientsAllTable>
        <vs-pagination
            :total="clients.number_of_pages"
            v-model="pageNumber"
        ></vs-pagination>
        <EditClientModal
            :show="selectedEdit"
            :data="selectedEdit"
            @close="selectedEdit = null"
            @newData="getPage"
        ></EditClientModal>
        <!-- Delete Clients Modal -->
        <vs-popup title="Delete Selected Logs?" :active.sync="show.deleteModal">
            <vs-button
                v-on:click="deleteSelected"
                color="secondary"
                class="mr-3"
                >Confirm</vs-button
            >
            <vs-button v-on:click="show.deleteModal = false" type="flat"
                >Cancel</vs-button
            >
        </vs-popup>
        <UploadClientsModal
            :dataShow="show.uploadModal"
            :alwaysShowBroker="true"
            @show="show.uploadModal = $event"
            @close="show.uploadModal = false"
            @refresh="getPage"
        ></UploadClientsModal>
    </div>
</template>

<script>
import ClientsAllTable from './Table'
import { http } from '@/services'
import EditClientModal from './Modals/EditModal'
import UploadClientsModal from './Modals/UploadClients'

export default {
    name: 'ClientsAll',
    components: {
        ClientsAllTable,
        EditClientModal,
        UploadClientsModal,
    },
    created() {
        if (this.$route.params.page)
            this.pageNumber = Number(this.$route.params.page)
        this.getPage()
    },
    beforeRouteUpdate(to, from, next) {
        next()
        if (this.$route.params.page)
            this.pageNumber = Number(this.$route.params.page)
        this.getPage()
    },
    watch: {
        pageNumber(newValue, oldValue) {
            if (Number(newValue) !== Number(oldValue))
                this.$router.push({
                    name: 'Clients',
                    params: { page: Number(newValue) },
                })
        },
    },
    computed: {},
    data() {
        return {
            clients: null,
            pageNumber: 1,
            itemsPerPage: 10,
            selected: [],
            selectedEdit: null,
            queriedItems: 20,
            limit: 'all',
            view: 'all',
            searchQuery: null,
            show: {
                deleteModal: false,
                uploadModal: false,
            },
        }
    },
    methods: {
        setItemsPerPage(value) {
            this.itemsPerPage = value
            this.getPage()
        },
        setPageNumber(value) {
            this.pageNumber = value
            this.getPage()
        },
        setView(value) {
            this.searchQuery = null
            this.view = value
            this.getPage()
        },
        getPage() {
            http.get('clients_list', {
                params: {
                    limit: this.limit,
                    items_per_page: this.itemsPerPage,
                    page_number: this.pageNumber,
                    query: this.searchQuery,
                    view: this.view,
                },
            })
                .then((response) => {
                    this.clients = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        search(value) {
            this.view = null
            this.searchQuery = value
            this.getPage()
        },
        setLimit(value) {
            this.limit = value
            this.getPage()
        },
        deleteSelected() {
            var selectedArray
            if (Array.isArray(this.selected))
                selectedArray = this.selected.map((data) => data.id)
            else selectedArray = [this.selected.id]

            http.post('clients_multiple_delete', selectedArray)
                .then((response) => {
                    this.getPage()
                    this.selected = []
                    this.show.deleteModal = false
                    this.$vs.notify({
                        text: 'Clients deleted',
                        color: 'success',
                    })
                })
                .catch((error) => {
                    console.log(error)
                    this.$vs.notify({
                        text: error.response.data,
                        color: 'danger',
                    })
                })
        },
    },
}
</script>

<style lang="scss">
#data-list-list-view {
    .vs-con-table {
        /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
        @media (max-width: 689px) {
            .vs-table--search {
                margin-left: 0;
                max-width: unset;
                width: 100%;

                .vs-table--search-input {
                    width: 100%;
                }
            }
        }

        @media (max-width: 461px) {
            .items-per-page-handler {
                display: none;
            }
        }

        @media (max-width: 341px) {
            .data-list-btn-container {
                width: 100%;

                .dd-actions,
                .btn-add-new {
                    width: 100%;
                    margin-right: 0 !important;
                }
            }
        }

        .product-name {
            max-width: 23rem;
        }

        .vs-table--header {
            display: flex;
            flex-wrap: wrap;
            margin-left: 1.5rem;
            margin-right: 1.5rem;
            > span {
                display: flex;
                flex-grow: 1;
            }

            .vs-table--search {
                padding-top: 0;

                .vs-table--search-input {
                    padding: 0.9rem 2.5rem;
                    font-size: 1rem;

                    & + i {
                        left: 1rem;
                    }

                    &:focus + i {
                        left: 1rem;
                    }
                }
            }
        }

        .vs-table {
            border-collapse: separate;
            border-spacing: 0 1.3rem;
            padding: 0 1rem;

            tr {
                box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
                td {
                    padding: 20px;
                    &:first-child {
                        border-top-left-radius: 0.5rem;
                        border-bottom-left-radius: 0.5rem;
                    }
                    &:last-child {
                        border-top-right-radius: 0.5rem;
                        border-bottom-right-radius: 0.5rem;
                    }
                }
                td.td-check {
                    padding: 20px !important;
                }
            }
        }

        .vs-table--thead {
            th {
                padding-top: 0;
                padding-bottom: 0;

                .vs-table-text {
                    text-transform: uppercase;
                    font-weight: 600;
                }
            }
            th.td-check {
                padding: 0 15px !important;
            }
            tr {
                background: none;
                box-shadow: none;
            }
        }

        .vs-table--pagination {
            justify-content: center;
        }
    }
}
</style>
